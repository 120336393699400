<template>
    <div>
        <el-cascader
            ref="elCascader"
            v-model="val"
            :clearable="clearable"
            :disabled="disabled"
            :placeholder="placeholder"
            :options="options"
            :filterable="filterable"
            :props="cascaderProps"
            @change="optionChange">
        </el-cascader>
    </div>
</template>

<script>
import { initTreeData } from '@/util/common'
export default {
    name: "Dictionary",
    components: {},
    props: {
        filterable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {//是否显示清空按钮
            type: Boolean,
            default: true
        },
        checkStrictly: {//是否可以选择分支节点
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        valKey: {
            type: String,
            default: 'val'
        },
        expandTrigger: {
            type: String,
            default: 'hover'
        },
        multiple:{
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'name'
        },
        code: {
            type: String
        },
        value: {}
    },
    data() {
        return {
            that: this,
            val: '',
            options: [],
            cascaderProps:{
                multiple:this.multiple,
                expandTrigger: this.expandTrigger,
                checkStrictly:this.checkStrictly,
                value:this.valKey,
                label:this.label,
                emitPath:false,
            }
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        code:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                if(newVal){
                    this.init();
                }
            }
        },
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                if(this.multiple){
                    if(newVal){
                        this.val = newVal.split(",");
                    };
                }else {
                    this.val = newVal;
                };
                //this.val = newVal;
            }
        },
    },
    mounted() {},
    methods: {

        init() {

            this.$api['admin/dict'].findAllDictByCode(this.code, true).then(res => {

                this.options = initTreeData(res.data);

            }).catch(err => {
                console.log(err);
            });

        },
        optionChange(v) {
            var value = '';

            if(v){
                if(this.multiple){
                    value = v.join();
                }else {
                    value = v
                };
            };



            this.$emit('dictionaryChange', this.$refs.elCascader.getCheckedNodes());
            this.$emit('change', value);
        }
    }
};
</script>
